.ez-dropdown {
    padding: 10px;
    position: relative;
    z-index: 99;
}

.ez-dropdown-toggle {
    border: 0;
    outline: 0;
    background-color: transparent;
    position: relative;
}


.ez-dropdown-toggle>i {
    font-size: 2rem;
    color: var(--txt-color);
}

.ez-dropdown-toggle-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -12px;
    right: -10px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: var(--main-color);
    color: var(--txt-color);
    font-size: 0.7rem;
}

.ac-container {
    display: flex;
    justify-content: flex-end;
}

.ez-dropdown-content {
    position: absolute;
    cursor: pointer;
    color: var(--txt-color);
    top: calc(100% + 1px);
    right: calc(50% + 15px);
    width: max-content;
    max-width: 400px;
    background-color: var(--main-bg);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    overflow: hidden;
    transform-origin: top right;
    transform: scale(0);
    transition: transform 0.3s ease 0s;
}


.ez-dropdown-content.active {
    transform: scale(1);
    transition: transform 0.5s var(--transition-cubic);
}