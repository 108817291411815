.ez-main-card {
  display: flex;
  flex-wrap: wrap;
}

.ez-card {
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: 2px;
  /* border-radius: var(--border-radius); */
  position: relative;
  overflow: hidden;
  transition: color 0.5s ease 0s;
  margin-bottom: 30px;
  border: 2px solid #367588;
}

#vertical {
  height: 150px;
  padding-bottom: 50px !important;
}

.ez-card-icon {
  width: 30%;
  height: 100%;
  font-size: 3rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.ez-card-icon-vertical {
  width: 100%;
  height: 100%;
  font-size: 3rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.ez-card-icon-vertical h6{
  font-size: 1.2rem;
}

.ez-card-info {
  flex-grow: 1;
  text-align: center;
  z-index: 1;
  text-transform: capitalize;
}

.ez-card-info > h4 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.ez-card::before {
  content: "";
  width: 100%;
  padding-top: 100%;
  border-radius: 50%;

  background-image: linear-gradient(
    to top right,
    var(--main-color),
    var(--second-color)
  );
  position: absolute;
  left: -50%;
  top: 0;
  transform: scale(0);
  transition: transform 0.6s ease-in-out 0s;
}

.ez-card:hover::before {
  transform: scale(3);
}

.ez-card:hover {
  color: var(--txt-white);
  border: 2px solid #ffffff;
}

.ez-auct-card {
  border: 3px solid;
  padding: 10px 0 10px 20px;
  color: var(--txt-gold);
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: 600;
}

.ez-auct-body {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0 10px 10px;
}

.ez-auct-items > span {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px;
  font-size: 1.2rem;
}

.ez-btn-holder {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 20px;
}

.ez-btn-holder > button {
  margin-right: 5px;
  border-radius: 5px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
}
