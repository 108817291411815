.breadcrumbs {
  background-color: unset;
  height: 45px;
  display: flex;
  align-items: center;
  padding: "0px 16px";
  padding-left: 20;
}

.breadcrumbs span {
  font-weight: 400;
}

.breadcrumbs .currentPage {
  color: var(--txt-color) !important;
  font-size: medium;
}

/* backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
      color: theme.palette.primary.main, */
