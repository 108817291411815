.ez-table-wrapper {
  overflow-y: auto;
}

table {
  width: 100%;
  min-width: 300px;
  border-spacing: 0;
}

thead {
  background-color: var(--second-bg);
  text-transform: capitalize;
  cursor: pointer;
}

tr {
  text-align: left;
}

th,
td {
  padding: 8px;
  text-align: left;
  /* white-space: nowrap;
  overflow: hidden;
  max-width: 400px; */
}

.ez-download .bx {
  font-size: 1.7rem;
  padding-left: 0.2em;
}

.ez-download {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 0 4px;
  margin-bottom: 12px;
}

.ez-search {
  margin-bottom: 20px;
  position: relative;
  height: 40px;
  background-color: var(--main-bg);
  display: flex;
  align-items: center;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  overflow: hidden;
}

.right {
  display: flex;
  align-items: center;
  margin: 0px 10px 4px;
}
.right > span {
  margin-right: 5px;
}
.right > select {
  margin-right: 5px;
  font-size: 1rem;
  border-radius: 8px;
  background-color: var(--main-bg);
  color: var(--txt-color);
}

.rightMost {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.leftMost {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.right button {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  color: var(--txt-color);
  letter-spacing: 0px;
  margin: 0px;
  border: 1px solid var(--txt-color);
  background-color: unset;
  cursor: pointer;
  transition: all 0.1s ease;
}

.right button:hover {
  background-color: var(--main-bg);
  color: var(--txt-color);
  font-weight: 600;
}

.right button:active {
  border: 2px solid var(--main-color);
  box-shadow: 0px 0px 0px 2px var(--main-color);
}

.right button:disabled {
  background-color: unset;
  cursor: default;
  border: 1px solid #e5e5e5;
  color: #e1e1e1;
  font-weight: 500;
}

.ez-search > input {
  border: 2px solid transparent;
  outline: 0;
  height: 100%;
  width: 100%;
  padding: 10px 60px 10px 20px;
  font-size: 1rem;
  border-radius: var(--border-radius);
  color: var(--txt-color);
  background-color: var(--main-bg);
}

.ez-search > input:focus {
  border: 2px solid var(--main-color);
}

.ez-search > i {
  font-size: 1.5rem;
  position: absolute;
  right: 20px;
}

tbody > tr:hover {
  background-color: var(--main-color);
  color: var(--txt-white);
}

.table-pagination {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.table-range {
  display: flex;
  width: 100%;
}

.table-range > span,
select {
  font-size: 0.8rem;
  margin-right: 5px;
}

.table-pagination-item ~ .table-pagination-item {
  margin-left: 10px;
}

.table-pagination-item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.table-pagination-item:hover {
  background-color: var(--second-color);
  color: var(--txt-white);
}

.table-wrapper {
  overflow: auto;
  position: relative;
}

table {
  min-width: 100%;
  margin-bottom: 30px;
}

tr {
  position: relative;
}

th {
  text-align: left;
  font-weight: 500;
  background-color: #e1e1e16c;
  padding: 10px;
}

th span {
  position: relative;
  display: flex;
  height: 25px;
  display: flex;
  align-items: center;
}

th:hover .sort-marker {
  display: flex;
}

.sort-marker-visible {
  display: flex !important;
}

td {
  padding: 5px 8px;
  border: none;
}

.divider {
  height: 1px;
  background-color: transparent;
  border-bottom: 1px solid #e5e5e5;
}

.table-top-bar {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-top-bar .left {
  min-width: 250px;
  display: flex;
}

.table-top-bar .right {
  min-width: 150px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.action-button * {
  transition: all 0.3s ease-in;
}

.action-button:hover * {
  color: var(--colorPrimary) !important;
}

.td-action {
  width: 45px;
  text-align: right;
  position: relative;
}

.action-menu-icon {
  height: 30px;
  width: 30px;
  margin: 0px !important;
  line-height: unset;
  color: var(--colorSecondary) !important;
}

.action-menu-icon .icon {
  height: 20px !important;
  width: 20px !important;
}
.actions-popper {
  min-width: 100px;
  position: absolute;
  right: 10px;
  top: 25px;
  z-index: 10;
  background-color: var(--main-bg);
  color: var(--txt-color);
  box-shadow: 0px 4px 20px 5px rgba(100, 100, 100, 0.2);
  border-radius: 8px;
}

.download-popper {
  min-width: 100px;
  position: absolute;
  right: 53px;
  top: 40px;
  z-index: 10;
  background-color: white;
  box-shadow: 0px 4px 20px 5px rgba(100, 100, 100, 0.2);
  border-radius: 8px;
}

.flex-box {
  display: flex;
  align-items: center;
}

.empty-message-container {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
}

.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.text-align-center {
  text-align: center;
}

.print-table table {
  min-width: 100%;
  /* padding: 20px; */
  border-collapse: collapse;
}

.print-table table td {
  border: 1px solid #e5e5e5;
  padding: 5px 8px;
  font-size: 0.9rem;
}

.print-table table th {
  background-color: var(--colorPrimary);
  padding: 8px 0px;
  border: 1px solid #e5e5e5;
  color: white;
  font-weight: 500;
}

.print-table .print-footer {
  display: flex;
  padding: 10px;
  margin: 10px 0px;
  background-color: #f9f9f9;
  font-size: 0.7rem;
}

#print-report-heading {
  background-color: #f1f1f1;
  padding: 10px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
}

.print-table .print-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  font-size: 0.9rem;
}

.sort-marker {
  height: 20px;
  width: 20px;
  border-radius: 5px;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: #aaa9a9;
  right: 2px;
  top: 2px;
  position: absolute;
}

.sort-marker:hover {
  cursor: pointer;
}

.sort-marker .icon {
  height: 15px !important;
  width: 15px !important;
  color: white !important;
}

.visible-columns-popper {
  min-width: 130px;
  position: absolute;
  right: 100px;
  top: 40px;
  z-index: 10;
  background-color: white;
  box-shadow: 0px 4px 20px 5px rgba(100, 100, 100, 0.2);
  border-radius: 8px;
}

.visible-columns-popper .popper-heading {
  padding: 8px 16px;
  font-size: 0.8rem;
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
}

.visible-columns-popper .visible-columns-menu-item {
  display: flex;
  align-items: center;
}

.visible-columns-popper .custom-checkbox {
  height: 16px;
  width: 16px;
  border: 2px solid #888;
  border-radius: 3px;
  margin-right: 5px;
}
.visible-columns-menu-item .custom-check-icon svg {
  height: 10px;
  width: 10px;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-check-icon {
  height: 14px;
  width: 14px;
  color: white !important;
  background-color: #888;
}
