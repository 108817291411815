.ez-sidebar {
  width: 90px;
  height: 100vh;
  z-index: 2;
  position: fixed;
  left: 0;
  top: 0;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  transition: 0.5s var(--transition-cubic);
  scroll-behavior: smooth;
}

:not(.ez-sidebar.active).ez-layout-content {
  padding-left: 90px;
}

.ez-sidebar.active {
  width: var(--sidebar-width);
}

.ez-sidebar.active ~ .ez-layout-content {
  padding-left: var(--sidebar-width);
}

.ez-sidebar.active .ez-side-logo {
  opacity: 1;
  pointer-events: none;
}

.ez-sidebar.active .ez-sidebar-menu {
  left: 90%;
}

.ez-side-logo {
  height: 80px;
  display: flex;
  opacity: 0;
  pointer-events: none;
  align-items: center;
  /* justify-content: center; */
  padding-left: 40px;
}

.ez-sidebar-menu {
  position: absolute;
  left: 55%;
  top: 6px;
  font-size: 2rem;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  color: var(--main-color);
  transform: translateX(-50%);
  white-space: nowrap;
}

.ez-side-logo > img {
  height: 45px;
}

.ez-side-item {
  position: relative;
  padding: 0;
  justify-content: center;
  align-items: center;
}

.ez-item-title {
  display: none;
  margin-left: 5px;
  pointer-events: none;
}

.ez-sidebar.active .ez-item-title {
  display: block;
  pointer-events: auto;
}

.ez-tooltip {
  padding: 8px 16px;
  left: 120px;
  top: 0;
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.2);
  background: var(--main-bg);
  border-radius: var(--border-radius);
  transform: translate(-20%, -50%);
  white-space: nowrap;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  display: none;
}

.ez-sidebar.active:hover .ez-tooltip {
  display: none;
}

.ez-sidebar.active .ez-side-item-inner {
  justify-content: unset;
  display: none;
}

.ez-side-item:hover .ez-tooltip {
  position: absolute;
  transition: all 0.3s ease;
  display: flex;
  backdrop-filter: blur(1px);
  top: 50%;
}

.ez-side-item-inner {
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  display: flex;
}

.ez-sidebar.active .ez-side-item-inner {
  margin-left: 0;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  /* font-weight: 400; */
  transition: color 0.3 ease 0s;
  margin-bottom: 0;
}

.ez-side-child {
  display: flex;
  padding: 2px 2px 10px 10px;
  width: 200px;
  border: var(--border-radius);
  background: var(--main-bg);
}

.ez-sidebar.active .ez-side-child {
  display: flex;
  padding: 0 0 10px 40px;
  align-items: center;
  font-weight: 400;
  transition: color 0.3 ease 0s;
}

.ez-side-item-inner > i {
  font-size: 1.5rem;
}

.ez-side-item-inner > span {
  text-transform: capitalize;
}

.ez-side-item-inner:hover {
  color: var(--main-color);
  background-color: #dfdfdf;
}

.ez-side-child {
  color: var(--txt-color);
  border-radius: 5px;
  margin-top: 5px;
}

.ez-side-child:hover .ez-tooltip {
  display: none;
}

.ez-side-item-inner.active {
  height: 45px;
  /* border-radius: var(--border-radius); */
  background-image: linear-gradient(
    to right,
    var(--main-color),
    var(--second-color)
  );
  color: var(--txt-white);
}

.ez-side-child.active {
  height: 45px;
  border-radius: var(--border-radius);
  background-image: linear-gradient(
    to right,
    var(--main-color),
    var(--second-color)
  );
  color: var(--txt-white);
}
