.ez-tright-item ~ .ez-tright-item {
  margin-left: 10px;
}
.ez-topnav {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--topnav-height);
}

.ez-top-right {
  display: flex;
  align-items: center;
}

.ez-notif-item {
  display: flex;
  align-items: center;
  padding: 20px;
}

.ez-notif-item:hover {
  background-color: var(--second-bg);
}

.ez-notif-item > i {
  margin-right: 20px;
  font-size: 1.5rem;
}

.ez-tright-user {
  display: flex;
  align-items: center;
}

.ez-tright-user-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.ez-tright-user-img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ez-tright-username {
  font-size: 1rem;
  padding-left: 5px;
  color: var(--txt-color);
  font-weight: 600;
}

@media only screen and (max-width: 900px) {
  .ez-tright-user-img {
    display: none;
  }
}

/* .error{
  margin-left: 5px;
  font-size: 13px;
  color: red;
} */
