@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --main-bg: #ffffff;
  --second-bg: #fafafb;
  --txt-color: #455560;
  --txt-white: #fff;
  --txt-gold: rgba(184, 134, 11);
  --main-color: #367588;
  --main-color-teal: #367588;
  --second-color: #367588;
  /* #62b4ff; */
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-light: #ffffff;
  --second-bg-light: #fafafb;
  --txt-color-light: #455560;
  --box-shadow-light: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-dark: #2d2d2d;
  --second-bg-dark: #202020;
  --txt-color-dark: #bbbbbb;
  --box-shadow-dark: rgba(0, 0, 0, 0.2) 0px 5px 10px;

  --main-color-blue: #367588;
  /* #349eff; */
  --second-color-blue: #367588;

  --main-color-red: #fb0b12;
  --second-color-red: #ff4a6b;

  --main-color-cyan: #367588;
  --second-color-cyan: #367588;

  --main-color-green: #019707;
  --second-color-green: #4caf50;

  --main-color-orange: #d68102;
  --second-color-orange: #fca11a;

  --main-color-gold: #d7ae57;
  --second-color-gold: #d8aa47;

  --sidebar-width: 240px;
  --border-radius: 15px;
  --topnav-height: 90px;
  --transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);

  --gradient: linear-gradient(45deg, #367588, #800000ff);

  
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: "Roboto", sans-serif, Proxima Nova;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.4px;
  flex-wrap: wrap;
  width: 100%;

  
  
  /* background-image: linear-gradient(45deg, #367588, rgba(128, 0, 0, 0.182)); */
  /* background-size: 150%; */


  /* background-size: 5%;
  animation: bg-animation 40s infinite alternate;
  background-image: url("../images/dawa.png");
  background-repeat: repeat;
   background-color: #4555603a; 
  */
  /* background-color: #367588;  */
  /* background-image: url('../../assets/images/intro.jpg') !important;
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center; */
}

@keyframes bg-animation {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}

.ez-action {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ez-search-dial {
  background-color: var(--main-bg) !important;
  color: var(--txt-color) !important;
  padding: 0.4rem 1rem 0 1rem;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: var(--main-color);
}

button {
  cursor: pointer;
  border: 0;
  outline: 0;
  color: var(--txt-color);
}

ul {
  list-style-type: none;
}

fieldset {
  padding: 10px;
  border: 0.05rem var(--txt-color) solid;
  border-radius: var(--border-radius);
}

fieldset legend,
select legend {
  padding: 5px;
  color: var(--txt-color);
}

/* select,
select:hover {
  border-radius: var(--border-radius);
  background-color: var(--main-bg);
  padding: 2px;
  color: var(--txt-color);
} */

select > option {
  border-radius: var(--border-radius);
}

.ez-field {
  padding: 5px;
}

input:focus {
  border: var(--main-color);
}

.page-header {
  margin-bottom: 20px;
  text-transform: capitalize;
}

.card {
  padding: 30px;
  margin-bottom: 30px;
  background-color: #e0e0e0;
  /* var(--main-bg); */
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.css-rnmm7m-MuiPaper-root-MuiDialog-paper {
  background-color: var(--main-bg) !important;
  color: var(--txt-color) !important;
}

.ez-page-action {
  display: flex;
  transition: all 0.3s var(--transition-cubic);
}

.ez-page-action > i {
  display: flex;
  padding: 0px 0px 0px 10px;
  color: var(--txt-color);
  font-size: 2rem;
}

.full-height {
  height: 100%;
}

.card.full-height {
  height: calc(100% - 30px);
}

.card-header {
  text-transform: capitalize;
}

.card-header ~ .card-body {
  margin-top: 30px;
}

.card > div ~ div {
  margin-top: 30px;
}

.card-footer {
  text-align: center;
  text-transform: capitalize;
}

.light-background {
  background-color: var(--main-bg-light);
  color: #000;
}

.dark-background {
  background-color: var(--main-bg-dark);
  color: #fff;
}

.blue-color {
  background-color: var(--main-color-blue);
  color: #fff;
}

.red-color {
  background-color: var(--main-color-red);
  color: #fff;
}

.cyan-color {
  background-color: var(--main-color-cyan);
  color: #fff;
}

.green-color {
  background-color: var(--main-color-green);
  color: #fff;
}

.orange-color {
  background-color: var(--main-color-orange);
  color: #fff;
}

.gold-color {
  background-color: var(--main-color-gold);
  color: #fff;
}

.ez-layout-content{
  /* background-color: #019707 !important; */
  /* background-image: url("../images/dawa_bottle.png");
  background-position: right bottom;
  background-repeat:repeat-x;
  background-size: 3%; */

  /* background-image: url("../images/dawa.png");
  background-position: right bottom;
  background-repeat:repeat;
  background-size: 2%; */
}

.wf-comments-item {
  display: flex;
  padding: 5px 0px;
}

.wf-comments-item .bubble {
  background-color: #f6f6f6;
  padding: 8px 16px;
  border-radius: 16px;
  color: rgb(65, 64, 64);
  width: 100%;
}

.wf-comments-item .bubble div {
  margin: 8px 0px;
  font-size: 0.85rem;
}

h1,h2,h3,h4{
  font-family: "Roboto", sans-serif, Proxima Nova !important;
}