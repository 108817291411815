.app-tab-view {
  display: flex;
  align-items: center;
  /* background-color: #615e5e1f; */
  border-bottom: 1px solid #e5e5e5;
  font-size: 0.9rem;
}

.app-tab {
  padding: 0px 16px;
  min-width: 100px;
  height: 50px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  color: #888;
  transition: all 0.1s ease;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.app-tab-view .active-tab {
  border-bottom: 2px solid var(--colorPrimary);
  color: #555;
  font-weight: bold;
  font-size: 0.88rem;
}

.app-tab-view .disabled-tab {
  border-bottom: 2px solid transparent;
  background-color: unset !important;
  color: #d1d1d1 !important;
  cursor: not-allowed !important;
}

.app-tab-view .tab-badge {
  height: 23px;
  width: 23px;
  margin-left: 8px;
  background-color: var(--colorSecondary);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  border-radius: 50%;
  color: white;
}
