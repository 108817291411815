.layout {
    color: var(--txt-color);
    overflow: scroll;
   
}

::-webkit-scrollbar {
    width: .6rem;
}


.ez-layout-content {
    padding-left: var(--sidebar-width);
    background-color: var(--second-bg);
    min-height: 100vh;
}

.ez-content-main {
    padding: 30px;
}

@media only screen and (max-width: 900px) {

    .ez-layout-content {
        padding-left: 0px;
    }

}