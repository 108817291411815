@media (min-width: 901px) {
  .login-page {
    width: 30vw;
    margin-top: 15vh;
    margin-left: 35vw;
    text-align: center;
    height: 85vh;
    overflow: scroll;
    /* margin: 400px; */
  }
}

@media (min-width: 651px) and (max-width: 900px) {
  .login-page {
    width: 70vw;
    margin-top: 5vh;
    margin-left: 15vw;
    text-align: center;
    overflow: scroll;
  }
}

@media (max-width: 650px) {
  .login-page {
    width: 90vw;
    margin-top: 2vh;
    margin-left: 5vw;
    text-align: center;
    overflow: scroll;
  }
}

.login-page img {
  margin: 10px;
  cursor: pointer;
}

@media (min-width: 901px) {
  .register-page {
    width: 30vw;
    margin-top: 15vh;
    margin-left: 35vw;
    text-align: center;
    height: 85vh;
    overflow: scroll;
    /* margin: 400px; */
  }
}

@media (min-width: 651px) and (max-width: 900px) {
  .register-page {
    width: 70vw;
    margin-top: 5vh;
    margin-left: 15vw;
    text-align: center;
    overflow: scroll;
  }
}

@media (max-width: 650px) {
  .register-page {
    width: 90vw;
    margin-top: 2vh;
    margin-left: 5vw;
    text-align: center;
    overflow: scroll;
  }
}

.register-page img {
  margin: 10px;
  cursor: pointer;
}

#forgot {
  /* margin-top: 5px !important; */
  padding-bottom: 20px !important;
  text-align: right !important;
}

#styleRegister:hover {
  text-decoration: underline;
}

button {
  background-color: var(--main-color);
  /* #002366 !important; */
  color: white;
}

.gradient {
  background-image: var(--gradient);
}
