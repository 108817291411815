.input-class {
  margin-top: 10px;
  margin-bottom: 10px;
}

.form-input {
  padding: 2px;
  display: flex;
  align-items: left;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 0.5s ease 0s;
  margin-bottom: 30px;
}

.pass > .bx:hover {
  transition: all 0.3 ease-in-out;
  cursor: pointer;
  font-size: 1.15rem;
}

.form-input::before {
  content: "";
  width: 100%;
  padding-top: 100%;
  border-radius: 50%;

  background-image: linear-gradient(
    to top right,
    var(--main-color),
    var(--second-color)
  );
  position: absolute;
  left: -50%;
  top: 0;
  transform: scale(0);
  transition: transform 0.6s ease-in-out 0s;
}

.form-input:hover::before {
  transform: scale(3);
}

.form-input:hover {
  color: var(--txt-white);
}
