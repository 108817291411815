.intro{
    background-image: url('../../assets/images/intro_3_5.jpg') !important;
    /* background-image: url('../../assets/images/intro_5.jpg') !important; */
    background-size:cover; 
    background-repeat: no-repeat;
    background-position: center;
    background-color: #367588;
    padding:'3rem' !important
}

@media (min-width: 901px) {
  .intro {
    /* padding:'3rem' */
  }

  .grid-intro{
    background-color: #66666645;
    width: 50%;
    align-self: center;
    align-items: center;
    align-content: center;
    padding: 1rem;
    border-radius: 0.5rem;
    margin-left: 25%;
  }

  .intro-why{
    color: #444;
    line-height: 29px;
    list-style: none;
    font-weight: 100;   
    padding-left:'8rem'; 
    padding-right:'8rem';
    padding-bottom:'1rem'; 
    padding-top:'2rem';
  }
}

@media (min-width: 651px) and (max-width: 900px) {
  .intro {
    /* padding:'1.5rem' */
  }

  .grid-intro{
    background-color: #66666645;
    width: 70%;
    align-self: center;
    align-items: center;
    align-content: center;
    padding: 1rem;
    border-radius: 0.5rem;
    margin-left: 15%;
  }

  .intro-why{
    color: #444;
    line-height: 29px;
    list-style: none;
    font-weight: 100;   
    padding-left:'8rem'; 
    padding-right:'8rem';
    padding-bottom:'1rem'; 
    padding-top:'2rem';
  }
}

@media (max-width: 650px) {
  .intro {
    /* padding:'0.5rem' */
  }

  .grid-intro{
    background-color: #66666645;
    width: 80%;
    align-self: center;
    align-items: center;
    align-content: center;
    padding: 1rem;
    border-radius: 0.5rem;
    margin-left: 10%;
  }

  .intro-why{
    color: #444;
    line-height: 29px;
    list-style: none;
    font-weight: 100;   
    padding-left:'8rem'; 
    padding-right:'8rem';
    padding-bottom:'1rem'; 
    padding-top:'2rem';
  }
}



.text-bordered {
    color: #fff; /* Set the text color */
    background: linear-gradient(to bottom, #fff, #fff); /* Set the background color as a gradient */
    -webkit-background-clip: text; /* Clip the background to the text */
    background-clip: text;
    text-decoration: underline; /* Add an underline effect to the text */
    -webkit-text-fill-color: transparent; /* Make the text transparent */
    background-image: linear-gradient(to bottom, #fff, #fff); /* Same as the background color */
    
    
  }

  

  .grid-intro:hover h1{
    color: #367588 !important;
  }

  .top-menu{
    width: fit-content;
    border-radius: 0.2rem;
    padding: 0.3rem;
  }

  .top-menu:hover{
    background-color: #66666645;
    cursor: pointer;
  }

  

  p{
        font-weight: 300 !important;
  }