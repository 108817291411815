.result {
    /* height: 60px; */
    background-color: #bbb;
    width: 100%;
  }
  
  .result p {
    font-size: 30px;
    margin: 5px;
    text-align: right;
  }
  
  .calculator-body {
    max-width: 90%;
    margin: auto;
  }
  
  .button {
    width: 100%;
    display: block;
    background-color: #bbb;
  }
  
  .calc {
    width: 25%;
    height: 60px;
    font-size: 30px;
  }
  
  .calc:hover{
    background-color: #bbb;
    color: var(--main-color-teal) !important;
  }