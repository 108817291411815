.theme-menu {
  position: fixed;
  right: -300px;
  top: 0;
  width: 250px;
  height: 100vh;
  padding: 20px;
  z-index: 100;
  background-color: var(--main-bg);
  box-shadow: var(--box-shaadow);
  transition: right 0.5s var(--transition-cubic);
}

.theme-menu.active {
  right: 0;
}

.theme-menu-close {
  position: absolute;
  top: 17px;
  right: 20px;
  background-color: transparent;
  font-size: 1.5rem;
}

.theme-menu-select {
  margin-top: 40px;
}

.mode-list {
  margin-top: 20px;
}

.mode-list > li {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.mode-list > li ~ li {
  margin-top: 10px;
}

.mode-list-color {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  padding-right: 5px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 1.5rem;
}

.mode-list-color > i {
  transform: scale(0);
  transition: transform 0.5s var(--transition-cubic);
}

.mode-list-color.active > i {
  transform: scale(1);
}

button {
  background-color: var(--main-color-teal);
  /* #002366; */
  color: white;
}

#attachment{
  cursor: pointer;
  background-color: #dfdfdf;
  padding: 5px;
  margin: 2px;
  border-radius: 5px;
}

#attachment :hover{
  text-decoration: underline;
}
